import "./about.scss";

const About = () => {
  return (
    <div className="about">
        <div className="imgconatiner">
        <img src="/renometal baniere 1.png" alt="Bannière RÉNOMETAL" width="100%" height="120px" />
        </div>
      <div className="top">
        <div className="block-3d">
          <h2>
            RÉNOMETAL : Votre Expert en Dépose-Repose en Site Occupé en
            Rhône-Alpes
          </h2>
          <p>
            Depuis nos débuts, RÉNOMETAL s'est imposé comme le leader incontesté
            des travaux de dépose-repose en sites occupés en Rhône-Alpes. Forts
            de notre expertise unique dans le domaine de l'amiante, nous
            garantissons une intervention rapide et sécurisée, assurant le hors
            d'eau et le maintien de vos activités au quotidien.
          </p>
        </div>
        <div className="block-3d">
          <h2>Certifications de Pointe pour une Intervention Sans Faille</h2>
          <p>
            RÉNOMETAL est fièrement certifié par MASE, garantissant notre
            engagement envers la sécurité, la santé et l'environnement. De plus,
            notre certification amiante par GLOBAL Certification atteste de
            notre compétence et de notre conformité aux normes les plus
            strictes. Lorsque vous choisissez RÉNOMETAL, vous optez pour une
            entreprise qui place la qualité et la sécurité au cœur de chaque
            projet.
          </p>
        </div>
        <div className="block-3d">
          <h2>
            Démarquez-vous avec RÉNOMETAL : L'Expertise au Service de Vos
            Projets
          </h2>
          <p>
            Dans une région où la concurrence est vive, RÉNOMETAL se distingue
            par son approche sur mesure et son souci du détail. Que ce soit pour
            un projet industriel, commercial ou résidentiel, nous offrons des
            solutions innovantes adaptées à vos besoins spécifiques.
          </p>
        </div>
      </div>
      <div className="bottom">
        <div className="block-3d">
          <h2>
            Dépose d'Amiante et Repositionnement Quotidien : Notre Spécialité
          </h2>
          <p>
            Nous comprenons l'importance de minimiser les perturbations lors de
            travaux en sites occupés. C'est pourquoi nous nous spécialisons dans
            la dépose d'amiante et le repositionnement du complexe final chaque
            jour. Notre équipe dédiée veille à ce que chaque étape soit réalisée
            avec précision, garantissant ainsi la continuité de vos opérations.
          </p>
        </div>
        <div className="block-3d">
          <h2>Contactez RÉNOMETAL Aujourd'hui pour une Solution Clé en Main</h2>
          <p>
            Situé au cœur de la Rhône-Alpes, RÉNOMETAL est prêt à transformer
            vos défis en opportunités. Contactez-nous dès aujourd'hui pour
            découvrir comment nous pouvons optimiser vos projets de
            dépose-repose en sites occupés.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
