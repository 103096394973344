import "./realisation.scss";
import { useRef } from "react";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";

const items = [
  {
    id: 1,
    title: "COUVERTURE",
    img: "couverture.png", 
    desc: "RÉNOMETAL est le leader national en solutions de couverture. Notre expertise en panneaux sandwich et bac acier garantit une protection durable et optimale. Chaque projet bénéficie de notre savoir-faire, assurant une couverture de qualité supérieure pour tous types de bâtiments.",
  },
  {
    id: 2,
    title: "BARDAGE",
    img: "bardage.png",
    desc: "RÉNOMETAL redéfinit l'esthétique des bâtiments avec des solutions de bardage haut de gamme. Qu'il s'agisse de simple peau ou de panneaux sandwich, nous apportons une finition impeccable, alliant esthétique et fonctionnalité.",
  },

  {
    id: 3,
    title: "ÉTANCHÉITÉ",
    img: "etancheite.png",
    desc: "Pour une étanchéité irréprochable, faites confiance à RÉNOMETAL. Spécialistes en membrane et étanchéité bitumineuse, nous offrons des solutions innovantes pour garantir la durabilité et la performance. Protégez vos structures des intempéries avec notre expertise reconnue.",
  },
  {
    id: 4,
    title: "DÉSAMIANTAGE",
    img: "desamiantage.png",
    desc: "La sécurité est primordiale lors du désamiantage. RÉNOMETAL est votre allié de confiance pour les interventions intérieures, extérieures et en sites occupés. Notre certification et expertise garantissent une intervention sécurisée et conforme aux normes.",
  },
];

const Single = ({ item }) => {
  const ref = useRef();

  const { scrollYProgress } = useScroll({
    target: ref,
  });

  const y = useTransform(scrollYProgress, [0, 1], [-300, 300]);

  return (
    <section id={item.title.toLowerCase()}>
      <div className="container">
        <div className="wrapper">
          <div className="imageContainer" ref={ref}>
            <img src={item.img} alt="" />
          </div>
          <motion.div className="textContainer" style={{ y }}>
            <h2>{item.title}</h2>
            <p>{item.desc}</p>
            <a href="#Contact"><button>Contact</button></a>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

const Realisation = ({ customId }) => {
  const ref = useRef();

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["end end", "start start"],
  });

  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
  });

  return (
    <div id={customId} className="portfolio" ref={ref}>
      <div className="progress">
        <h1>RÉNOMETAL : Expertise Totale</h1>
        <motion.div style={{ scaleX }} className="progressBar"></motion.div>
      </div>
      {items.map((item) => (
        <Single item={item} key={item.id} />
      ))}
    </div>
  );
};

export default Realisation;
