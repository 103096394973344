import { useRef } from "react";
import "./services.scss";
import { motion, useInView } from "framer-motion";

const variants = {
  initial: {
    x: -500,
    y: 100,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
      staggerChildren: 0.1,
    },
  },
};

const Services = () => {
  const ref = useRef();

  const isInView = useInView(ref, { margin: "-100px" });

  return (
    <motion.div
      className="services"
      variants={variants}
      initial="initial"
      // animate="animate"
      // whileInView="animate"
      ref={ref}
      animate={"animate"}
    >
      <motion.div className="textContainer" variants={variants}>
        <p>
          Expertise RÉNOMETAL :
          <br /> Dépose-Repose d'Amiante en Site Occupé en Rhône-Alpes
        </p>
        <hr />
      </motion.div>
      <motion.div className="titleContainer" variants={variants}>
        <div className="title">
          <img src="/services.png" alt="" />
          <h1>
            <motion.b whileHover={{ color: "orange" }}>RÉNOMETAL :</motion.b> Experts
          </h1>
        </div>
        <div className="title">
          <h1>
            <motion.b whileHover={{ color: "orange" }}>en Bâtiment</motion.b>{" "}
            & Désamiantage.
          </h1>
        </div>
      </motion.div>
      <motion.div className="listContainer" variants={variants}>
        <motion.div
          className="box"
          whileHover={{ background: "lightgray", color: "black" }}
        >
          <h2>Couverture : </h2>
          <p>
            RÉNOMETAL, spécialiste en couverture en Rhône-Alpes, propose des
            solutions innovantes avec panneaux sandwich et bac acier pour une
            protection optimale.
          </p>
          <a href="#couverture"><button>Couverture...</button></a>
        </motion.div>
        <motion.div
          className="box"
          whileHover={{ background: "lightgray", color: "black" }}
        >
          <h2>Étanchéité : </h2>
          <p>
            Assurez l'étanchéité parfaite de vos bâtiments avec RÉNOMETAL.
            Experts en membrane et étanchéité bitumineuse, nous garantissons une
            durabilité exceptionnelle.
          </p>
          <a href="#étanchéité"><button>Étanchéité...</button></a>
        </motion.div>
        <motion.div
          className="box"
          whileHover={{ background: "lightgray", color: "black" }}
        >
          <h2>Bardage : </h2>
          <p>
            RÉNOMETAL transforme l'esthétique de vos structures avec du bardage
            de qualité. Choisissez entre simple peau ou panneaux sandwich pour
            une finition impeccable.
          </p>
          <a href="#bardage"><button>Bardage...</button></a>
        </motion.div>
        <motion.div
          className="box"
          whileHover={{ background: "lightgray", color: "black" }}
        >
          <h2>Désamiantage :</h2>
          <p>
            RÉNOMETAL est votre partenaire de confiance pour le désamiantage
            intérieur, extérieur et en sites occupés, garantissant sécurité et
            conformité.
          </p>
          <a href="#désamiantage"><button>Désamiantage...</button></a>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default Services;
