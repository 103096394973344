import "./App.scss";
import Sidebar from "./components/sidebar/Sidebar";
import Navbar from "./components/navbar/Navbar";
import Home from "./components/home/Home";
import Services from "./components/services/Services";
import Realisation from "./components/realisation/Realisation";
import Contact from "./components/contact/Contact";
import About from "./components/about/About";



function App() {

  const realisationId ="Réalisation"
  return (
    <div>
      <section id="Accueil">
        <Navbar />
        <Home />
      </section>
      <section id="Services">
        <Services />
      </section>
      <section className="desktop-only">
        <About />
      </section>
      
      <Realisation customId={realisationId} />
      <section id="Contact">
        <Contact />
      </section>
    </div>
  );
}

export default App;
